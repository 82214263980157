<template>
  <div class="login">
    <div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
      <div class="card card0 border-3" style="border-radius:10px;">
        <div class="row d-flex">
          <div class="col-lg-7">
            <div class="card1 pb-5">
              <div class="row">
                <img src="/logo.png" class="logo" />
              </div>
              <div class="row px-3 justify-content-center mt-4 mb-5">
                <img src="/nuta.png" class="image" style="padding-top:100px" />
              </div>
            </div>
          </div>
          <div class="col-lg-5 border-line">
            <form @submit.prevent="submitOpenOutlet">
              <div class="card2 card border-0 px-4 py-5">
                <div class="row">
                  <div class="col-lg-12 text-center">
                    <img src="/close.png" style="padding-top:10px" />
                  </div>
                </div>
                <div class="text-center">  
                  <h6><b>{{outletName}} {{outletAddress}}</b></h6>
                  <hr style="margin:10px 0px 10px 0px">
                  <h6 style="font-size:14px"><b>Tutup {{history}}</b></h6>
                </div>
                <div class="row"  style="padding-top:5%" v-if="saldokasdevice > 0">
                  <label class="col-lg-12 text-center" style="font-size:14px;">
                    Silahkan masukan tambahan modal untuk membuka shift
                  </label>
                  <div class="col-lg-12">
                    <div class="form-group" style="margin-top:-10px;">
                      <label class="floating-custom">Sisa Uang di laci</label>
                       <label class="rupiah">Rp</label>
                      <currency-input
                        class="form-control text-left"
                        style="padding-left:50px;"
                        v-model="saldokasdevice"
                        type="text"
                        name="modal"
                        :options="{ locale: 'de-DE', currency: 'EUR', currencyDisplay: 'hidden', autoSign: false }"
                        placeholder=""
                        disabled
                        autocomplete="off"
                        autocorrect="off"/>
                    </div>
                    <div class="form-group">
                      <label class="floating-custom">Tambahan Modal</label>
                      <label class="rupiah">Rp</label>
                      <currency-input
                        class="form-control text-left"
                        style="padding-left:50px;"
                        v-model="form.modalawal"
                        type="text"
                        name="modal"
                        :options="{ locale: 'de-DE', currency: 'EUR', currencyDisplay: 'hidden', autoSign: false }"
                        placeholder=""/>
                      <i v-if="errors"><small class="text-danger">{{ errors }}</small></i>
                    </div> 
                  </div>                   
                </div>

                <div class="row"  style="padding-top:10%" v-else>
                    <label class="mb-1 col-lg-12 text-center">
                      Silahkan masukan modal awal untuk membuka shift
                    </label>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="floating-custom">Modal Awal</label>
                        <label class="rupiah2">Rp</label>
                        <currency-input
                          class="form-control text-left"
                          style="padding-left:50px;"
                          v-model="form.modalawal"
                          type="text"
                          name="modal"
                          :options="{ locale: 'de-DE', currency: 'EUR', currencyDisplay: 'hidden', autoSign: false }"
                          placeholder="0"
                          autocomplete="off"
                          autocorrect="off"/>
                        <i v-if="errors"><small class="text-danger">{{ errors }}</small></i>
                      </div>
                    </div> 
                </div>
                
                <div class="row mb-3" style="padding-top:5%;padding-left:65%">
                  <button
                    type="submit"
                    class="btn btn-green text-center"
                    :disabled="submitted"
                    style="border-radius:5px;"
                  >
                    <span v-html="btnSimpan"></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import CurrencyInput from '@/components/CurrencyInput_c';

export default {
  data() {
    return {
      form: {
         modalawal:null,
      },
      saldokasdevice:0,
      submitted: false,
      errors: null,
      btnSimpan:"Buka Shift",
      history:""
    };
  },
  components: {
    CurrencyInput,
  },
  computed: {
    ...mapGetters(["outletName","outletAddress"]),
  },
  mounted() {
      this.getHistoryOpen();
      this.getSaldokasDevice();
  },
  methods: {
    // get Sisa Uang di laci sebelumnya 
    getSaldokasDevice(){
        this.$store
        .dispatch("actGetSaldoKasDevice")
        .then((result) => {
            this.saldokasdevice = result;
            if (this.saldokasdevice>0){
                this.form.modalawal = 0
            }
        })
    },
    // get data open close outlet terakhir
    getHistoryOpen(){
        this.$store
        .dispatch("actGetHistoryOpen")
        .then((result) => {
            this.history = result
        })
    },
    // submit open outlet
    submitOpenOutlet() {
      this.submitted = true;
      this.btnSimpan = "<div class='fa fa-circle-notch fa-spin'></div>";
      if (this.form.modalawal == null){
        if (this.saldokasdevice>0)
        {
            this.errors = "Tambahan Modal tidak boleh kosong";
        }
        else{
            this.errors = "Modal awal tidak boleh kosong";
        }
        this.submitted = false;
        this.btnSimpan = "Buka Shift";
      }
      else if (this.form.modalawal < 0){
        if (this.saldokasdevice>0)
        {
            this.errors = "Tambahan Modal awal tidak boleh Minus";
        }
        else{
            this.errors = "Modal awal tidak boleh Minus";
        }
        this.submitted = false;
        this.btnSimpan = "Buka Shift";
      }
       else {
         var dataToSent = {
           modalSebelumnya : this.saldokasdevice,
           modalInput : this.form.modalawal
         }
        this.$store
          .dispatch("actOpenOutlet", dataToSent)
          .then(() => {
              this.submitted = false;
              //this.$store.dispatch("actReSyncDataSale")
              setTimeout(() => this.$store.dispatch("actReSyncDataSale"), 2000);
              this.$router.push({name: 'Kasir'})
              this.btnSimpan = "Buka Shift";
          })
          .catch((error) => {
            console.log(error)
            this.errors = error.statusText;
            this.submitted = false;
            this.btnSimpan = "Buka Shift";
          });
      }
    },
  },
};
</script>

<style scoped>
body {
  color: #000;
  overflow-x: hidden;
  height: 100%;
  background-repeat: no-repeat;
}

.card0 {
  box-shadow: 0px 3px 8px 0px #757575;
  border-radius: 0px;
}

.card2 {
  margin: 0px 40px;
}

.logo {
  width: 150px;
  height:30px;
  transform: translateX(30px) translateY(21px);
}

.image {
  width: 650px;
  height: auto;
}

.border-line {
  border-left: 1px solid #eeeeee;
  margin-top:10px;
  margin-bottom:10px;
}

.or {
  width: 10%;
  font-weight: bold;
}

input,
textarea {
  padding: 10px 12px 10px 12px;
  border: 1px solid rgb(148, 148, 148);
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
  color: #000000;
  font-size: 14px;
  letter-spacing: 1px;
}

input:focus,
textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #00ae2b;
  outline-width: 0;
}


button:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline-width: 0;
}

a {
  color: inherit;
  cursor: pointer;
}

.btn-green {
  background-color: #00ae2b;
  width: 150px;
  color: #fff;
  border-radius: 2px;
}

.btn-green:hover {
  background-color: #019b28;
  cursor: pointer;
}
.floating-custom {
    font-size: 14px;
    transform: translateY(95%) translateX(10%);
    background:#fff;
}
.floating-custom-password {
    font-size: 14px;
    transform: translateY(60%) translateX(10%);
    background:#fff;
    z-index: 1;
}
.rupiah{
    position: absolute;
    width:30px;
    margin-left:-90px;
    z-index: 10;
    transform: translateY(115%) translateX(0%);
    height:35px; 
}
.rupiah2{
    position: absolute;
    width:30px;
    margin-left:-60px;
    z-index: 10;
    transform: translateY(115%) translateX(0%);
    height:35px; 
}
@media screen and (max-width: 991px) {
  .logo {
    margin-left: 0px;
  }

  .image {
    width: 300px;
    height: 220px;
  }

  .border-line {
    border-right: none;
  }

  .card2 {
    border-top: 1px solid #eeeeee !important;
    margin: 0px 15px;
  }
}
</style>